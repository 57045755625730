import { useCallback, useMemo } from "react";
import moment from "moment";
import { message } from "antd";
import { create } from "@/api/message.js";
import { upperFirst, transform } from "lodash";
import useInputEntity from "@/hooks/useInputEntity";
import { dateTimeFormat, notifyBy } from "@/config/constants";
import { toTransportableDateTime } from "@/utils/converters.js";

const KEYS = new Set([
    "customerId", "scheduledAt", "channel", "title", "body"
]);

const transformToServer = source => transform(source, (result, value, key) =>
{
    if (!KEYS.has(key))
    {
        return;
    }

    const resultKey = upperFirst(key);

    let resultValue;
    switch (key)
    {
    case "scheduledAt":
        resultValue = value ? toTransportableDateTime(value) : undefined;
        break;
    default:
        resultValue = value;
        break;
    }

    result[resultKey] = resultValue;
});

const useNotifyForm = (ids, onCatchRequestError) =>
{
    const {
        state,
        handleLoadingSet,
        handleLoadingReset,
        handleFieldChange,
        handleFieldInnerChange,
        handleFieldInnerRemove,
        handleInputChange,
        handleCheckboxChange
    } = useInputEntity({
        loading: false,
        customerId: ids,
        channel: notifyBy.none,
        toPhone: false,
        toEmail: false,
        toFireBase: false,
        title: undefined,
        body: "",
        scheduledAt: ""
    });

    const handleNotifyCreate = useCallback(
        () =>
        {
            handleLoadingSet();

            const notify = transformToServer(state);

            // создаём уведомление
            create(notify)
                .then(response =>
                {
                    const { meta, errors } = response.data;

                    if (meta.status === 200)
                    {
                        message.success("Уведомление отправлено");
                    }
                    else
                    {
                        message.error(errors[0]);
                    }
                })
                .catch(onCatchRequestError)
                .finally(handleLoadingReset);
        },
        [handleLoadingSet, handleLoadingReset, state]
    );

    const onChannelChange = (event, selectedChannel) =>
    {
        handleCheckboxChange(event);

        const { target: { checked } } = event;

        const { channel } = state;

        handleFieldChange(
            "channel",
            checked
                ? channel | selectedChannel
                : channel & !selectedChannel);
    };

    const scheduledAt = useMemo(
        () => state.scheduledAt ? moment(state.scheduledAt, dateTimeFormat) : undefined,
        [state.scheduledAt]
    );
    const scheduledAtChange = useCallback((_, dateString) => handleFieldChange("scheduledAt", dateString), [handleFieldChange]);

    return {
        loading: state.loading,
        handleFieldInnerChange,
        handleFieldInnerRemove,
        handleInputChange,
        handleCheckboxChange,
        handleLoadingSet,
        handleLoadingReset,

        ids: state.ids,
        toPhone: state.toPhone,
        toEmail: state.toEmail,
        toFireBase: state.toFireBase,
        title: state.title,
        body: state.body,
        scheduledAt,

        handleNotifyCreate,
        onChannelChange,
        scheduledAtChange
    };
};

export default useNotifyForm;
