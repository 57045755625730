import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Input, Row, Col, Button, Checkbox, Spin, DatePicker } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { toggleSettingPanel } from "@/store/actions";
import { useForm } from "@/components/Form";
import { dateTimeFormat, notifyBy } from "@/config/constants";
import useNotifyForm from "./hooks/useNotifyForm.js";

const Create = props =>
{
    const {
        data
    } = props;
    const { ids } = data;
    const { onCatchRequestError } = useForm();
    const {
        loading,
        handleInputChange,
        toPhone,
        toEmail,
        toFireBase,
        title,
        body,
        scheduledAt,
        handleNotifyCreate,
        onChannelChange,
        scheduledAtChange
    } = useNotifyForm(ids, onCatchRequestError);

    return (
        <Spin spinning={loading}>
            <Row className="row-simple" gutter={[0, 10]}>
                <Col className="bold" span={24}>
                    <Checkbox
                        defaultChecked={toPhone}
                        name="toPhone"
                        onChange={event => onChannelChange(event, notifyBy.sms)}
                    >
                            SMS
                    </Checkbox>
                </Col>
                <Col className="bold" span={24}>
                    <Checkbox
                        defaultChecked={toEmail}
                        name="toEmail"
                        onChange={event => onChannelChange(event, notifyBy.mail)}
                    >
                            Email
                    </Checkbox>
                </Col>
                <Col className="bold" span={24}>
                    <Checkbox
                        defaultChecked={toFireBase}
                        name="toFireBase"
                        onChange={event => onChannelChange(event, notifyBy.push)}
                    >
                            Уведомление на телефон
                    </Checkbox>
                </Col>
            </Row>
            <Row className="row-simple">
                <Col className="bold" span={12}>Когда отправить</Col>
                <Col span={12}>
                    <DatePicker
                        className="full-width"
                        format={dateTimeFormat}
                        onChange={scheduledAtChange}
                        showTime
                        value={scheduledAt}
                    />
                </Col>
            </Row>
            <Row className="row-simple" gutter={[0, 10]}>
                <Col className="bold" span={24}>Заголовок сообщения</Col>
                <Col span={24}>
                    <Input
                        disabled={!toEmail && !toFireBase}
                        name="title"
                        onChange={handleInputChange}
                        placeholder="Введите заголовок"
                        value={title} />
                </Col>
            </Row>
            <Row className="row-simple" gutter={[0, 10]}>
                <Col className="bold" span={16}>Сообщение</Col>
                <Col span={24}>
                    <Input
                        disabled={!toEmail && !toPhone && !toFireBase}
                        name="body"
                        onChange={handleInputChange}
                        placeholder="Введите сообщение"
                        value={body} />
                </Col>
            </Row>
            <Row className="row">
                <Col span={24}>
                    <Button
                        className="green-button"
                        icon={<CheckOutlined />}
                        onClick={handleNotifyCreate}
                        type="primary"
                    >
                            Отправить
                    </Button>
                </Col>
            </Row>
        </Spin>
    );
};

export default connect(
    state => state.user,
    { toggleSettingPanel }
)(withRouter(Create));
